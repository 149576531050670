<template>
    <div class="pt-2">
        <div>
            <h3 class="float-left">Данни от входовете на инвертора</h3>
            <div class="float-right">
                <b-button  size="sm" variant="outline-danger" @click="$router.push({name: 'devices'})">
                    <b-icon icon="arrow-left-circle-fill" font-scale="1"></b-icon> Назад
                </b-button>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="mt-4">
            <b-skeleton-table
            :rows="8"
            :columns="4"
            :table-props="{ bordered: false, striped: true }"  v-if="!isLoaded"
            ></b-skeleton-table>
            <table class="table" v-if="isLoaded">
                <thead class="thead-light">
                    <tr>
                    <th scope="col">Стринг</th>
                    <th scope="col">Напрежение (V)</th>
                    <th scope="col">Ток (A)</th>
                    <th scope="col">Мощност (W)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">Вход 1</th>
                        <td>{{ deviceData.pv1_voltage }}</td>
                        <td>{{ deviceData.pv1_current }}</td>
                        <td>{{ deviceData.pv1_voltage * deviceData.pv1_current * deviceData.power_factor | toInt }}</td>
                    </tr>
                    <tr>
                        <th scope="row">Вход 2</th>
                        <td>{{ deviceData.pv2_voltage }}</td>
                        <td>{{ deviceData.pv2_current }}</td>
                        <td>{{ deviceData.pv2_voltage * deviceData.pv2_current * deviceData.power_factor | toInt }}</td>
                    </tr>
                    <tr>
                        <th scope="row">Вход 3</th>
                        <td>{{ deviceData.pv3_voltage }}</td>
                        <td>{{ deviceData.pv3_current }}</td>
                        <td>{{ deviceData.pv3_voltage * deviceData.pv3_current * deviceData.power_factor | toInt }}</td>
                    </tr>
                    <tr>
                        <th scope="row">Вход 4</th>
                        <td>{{ deviceData.pv4_voltage }}</td>
                        <td>{{ deviceData.pv4_current }}</td>
                        <td>{{ deviceData.pv4_voltage * deviceData.pv4_current * deviceData.power_factor | toInt }}</td>
                    </tr>
                    <tr>
                        <th scope="row">Вход 5</th>
                        <td>{{ deviceData.pv5_voltage }}</td>
                        <td>{{ deviceData.pv5_current }}</td>
                        <td>{{ deviceData.pv5_voltage * deviceData.pv5_current * deviceData.power_factor | toInt }}</td>
                    </tr>
                    <tr>
                        <th scope="row">Вход 6</th>
                        <td>{{ deviceData.pv6_voltage }}</td>
                        <td>{{ deviceData.pv6_current }}</td>
                        <td>{{ deviceData.pv6_voltage * deviceData.pv6_current * deviceData.power_factor | toInt }}</td>
                    </tr>
                    <tr>
                        <th scope="row">Вход 7</th>
                        <td>{{ deviceData.pv7_voltage }}</td>
                        <td>{{ deviceData.pv7_current }}</td>
                        <td>{{ deviceData.pv7_voltage * deviceData.pv7_current * deviceData.power_factor | toInt }}</td>
                    </tr>
                    <tr>
                        <th scope="row">Вход 8</th>
                        <td>{{ deviceData.pv8_voltage }}</td>
                        <td>{{ deviceData.pv8_current }}</td>
                        <td>{{ deviceData.pv8_voltage * deviceData.pv8_current * deviceData.power_factor | toInt }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="mt-4">
            <h3>Данни за мрежата</h3>
        </div>
        <div class="mt-4">
            <b-skeleton-table
            :rows="3"
            :columns="4"
            :table-props="{ bordered: false, striped: true }"  v-if="!isLoaded"
            ></b-skeleton-table>
            <table class="table"  v-if="isLoaded">
                <thead class="thead-light">
                    <tr>
                    <th scope="col">Параметър</th>
                    <th scope="col">Фаза R</th>
                    <th scope="col">Фаза S</th>
                    <th scope="col">Фаза T</th>
                    </tr>
                </thead>
                <tbody>
                <tr>
                    <th scope="row">Ток (A)</th>
                    <td>{{ deviceData.ia }}</td>
                    <td>{{ deviceData.ib }}</td>
                    <td>{{ deviceData.ic }}</td>
                    </tr>
                    <tr>
                    </tr><tr>
                    <th scope="row">Напрежение земя - фаза (V)</th>
                    <td>{{ deviceData.ua }}</td>
                    <td>{{ deviceData.ub }}</td>
                    <td>{{ deviceData.uc }}</td>
                    </tr>
                    <tr>
                    </tr><tr>
                    <th scope="row">Напрежение фаза - фаза (V)</th>
                    <td>R-S {{ deviceData.uab }}</td>
                    <td>S-T {{ deviceData.ubc }}</td>
                    <td>T-R {{ deviceData.uca }}</td>
                    </tr>
                    <tr>
                </tr></tbody>
            </table>
        </div>
        <div class="mt-4">
            <h3>Данни от последното обхождане</h3>
        </div>
        <div class="mt-4">
            <b-skeleton-table
            :rows="14"
            :columns="2"
            :table-props="{ bordered: false, striped: true }"  v-if="!isLoaded"
            ></b-skeleton-table>
            <table class="table"  v-if="isLoaded">
                <thead class="thead-light">
                    <tr>
                    <th scope="col">Име на параметъра</th>
                    <th scope="col">Стойност</th>
                    </tr>
                </thead>
                <tbody>
                    <tr><th scope="row">Температура на инвертора</th>
                    <td>{{ deviceData.cabinet_temperature }} &#176;C</td>
                    </tr>
                    <tr>
                    <th scope="row">Ефективност на инвертора</th>
                    <td>{{ deviceData.inverter_efficiency }} %</td>
                    </tr>
                    <tr>
                                        <th scope="row">Фактор на мощността</th>
                    <td>{{ deviceData.power_factor }}</td>
                    </tr>
                    <tr>
                                        <th scope="row">Честота</th>
                    <td>{{ deviceData.frequency }} HZ</td>
                    </tr>
                    <tr>
                                        <th scope="row">Статус на инвертора</th>
                    <td>{{ deviceData.inverter_status }}</td>
                    </tr>
                    <tr>
                                        <th scope="row">Пикова активна енергия за деня</th>
                    <td>{{ deviceData.active_power_peak_of_current_day }} KW</td>
                    </tr>
                    <tr>
                                        <th scope="row">Активна мощност</th>
                    <td>{{ deviceData.active_power }} KW</td>
                    </tr>
                    <tr>
                                        <th scope="row">Реактивна мощност</th>
                    <td>{{ deviceData.reactive_power }} KW</td>
                    </tr>
                    <tr>
                                        <th scope="row">Входяща мощност от панелите</th>
                    <td>{{ deviceData.total_input_power }} KW</td>
                    </tr>
                    <tr>
                                        <th scope="row">Отдадена енергия за текущия час</th>
                    <td>{{ deviceData.e_hour }} KW/h</td>
                    </tr>
                    <tr>
                                        <th scope="row">Отдадена енергия за текущия ден</th>
                    <td>{{ deviceData.e_day }} KW/h</td>
                    </tr>
                    <tr>
                                        <th scope="row">Отдадена енергия за текущия месец</th>
                    <td>{{ deviceData.e_month }} KW/h</td>
                    </tr>
                    <tr>
                                        <th scope="row">Отдадена енергия за текущата година</th>
                    <td>{{ deviceData.e_year }} KW/h</td>
                    </tr>
                    <tr>
                                        <th scope="row">Отдадена енергия тотал</th>
                    <td>{{ deviceData.e_total }} KW/h</td>
                    </tr>
                    <tr>
                                    </tr></tbody>
            </table>
        </div>
        <b-button  size="sm" variant="outline-danger" @click="$router.push({name: 'devices'})">
            <b-icon icon="arrow-left-circle-fill" font-scale="1"></b-icon> Назад
        </b-button>
    </div>
</template>

<script>
import axios from '../../middleware/axios'

export default {
    name: "Details",
    props: ['id'],
    data() {
        return {
            deviceData: null,
            isLoaded: false
        }
    },
    filters: {
        toInt: function(value) {
            return Math.round(value)
        }
    },
    mounted() {
        axios({ url: 'https://solareye.ntx.bg:9091/devices/' + this.id, method: 'GET'})
        .then(res => {
            console.log(res.data)
            this.deviceData = res.data[0]
            this.isLoaded = true
        })
    }
}
</script>